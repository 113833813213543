import React, { useContext, useEffect } from 'react';
import { KfHeroUtility } from '@klickinc/kf-react-components';
import AppContext from "@src/context"
import Loadable from '@loadable/component';
// Utility imports
import Seo from '@components/utility/SEO';
import { graphql } from 'gatsby';

import './styles.scss';

// Content imports
import BottomCta from '@components/content/BottomCta';
const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));

const videos = [
	{
		videoTitle: 'Here & now with the LoSapios',
		videoDescription: ['Mike has two sons with DMD. Here\'s how he makes sure they\'re making the most of the here & now.'],
		videoId: 740850133,
		videoTransript: '/video-transcript/here-now-losapios',
		videoAnchor: 'losapios',
		lang: 'en'
	},
	{
		videoTitle: 'Here & now with the Kasners',
		videoDescription: ['Sarah and Dan work together to care for their two sons with DMD. Watch how they try to have fun and enjoy life as much as they can.'],
		videoId: 778158200,
		videoTransript: '/video-transcript/here-now-kasners',
		videoAnchor: 'kasners',
		lang: 'en'
	},
	{
		videoTitle: 'Making A Switch: Christopher\'s Treatment Journey',
		videoDescription: ['Sima cares for her son every day with the help of her family. Hear how she works with the DMD community and ', <span key="videoDescription-making-a-switch">PTC&nbsp;<span className="italic">Cares</span>™</span>, ' along the way.'],
		videoId: 768695028,
		videoTransript: '/video-transcript/making-a-switch',
		videoAnchor: 'making-a-switch',
		lang: 'en'
	},
	{
		videoTitle: 'Haciendo un cambio: la historia de tratamiento de Christopher',
		videoDescription: ['Sima cuida de su hijo cada día con la ayuda de su familia. Escuche cómo trabaja con la comunidad de DMD y ', <span key="videoDescription-haciendo-un-cambio">PTC&nbsp;<span className="italic">Cares</span>™</span>, ' a lo largo del proceso.'],
		videoId: 507119354,
		videoTransript: '/video-transcript/haciendo-un-cambio',
		videoAnchor: 'haciendo-un-cambio',
		lang: 'es'
	},
	{
		videoTitle: 'A real-world story',
		videoDescription: ['Hear from a family as they discuss their experience with making treatment decisions.'],
		videoId: 644529296,
		videoTransript: '/video-transcript/he-is-manny',
		videoAnchor: 'he-is-manny',
		lang: 'en'
	},
	{
		videoTitle: 'Challenging Expectations',
		videoDescription: ['Find out how one DMD mom, and Peer Navigator, helps her son pursue a normal life by adapting activities to his abilities.'],
		videoId: 507118911,
		videoTransript: '/video-transcript/challenging-expectations',
		videoAnchor: 'challenging-expectations',
		lang: 'en'
	},
	{
		videoTitle: 'Making the Grade',
		videoDescription: ['Hear from a mother caring for her son with DMD on how she\'s setting him up for success in school.'],
		videoId: 507119104,
		videoTransript: '/video-transcript/making-the-grade',
		videoAnchor: 'making-the-grade',
		lang: 'en'
	},
];

const VideoLibrary = ({ data }) => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass('page--video-library');
	}, []);

	return (<>
		<section className='relative'>
			<div className="container container--inner">
				<KfHeroUtility addedClass='heading heading-hero-blue xl:pt-14'>
					Video library
				</KfHeroUtility>

				<div className='max-w-xl'>
					<p>Understanding and managing DMD can be difficult on your own. The videos below feature stories from families going through similar journeys along with other useful information. Watch them all to see if anything they have to say could help you and your family.</p>
				</div>
			</div>
		</section>

		<section className='pt-10 sm:pt-20'>
			<div className='videos container container--inner grid grid-cols-1 sm:grid-cols-2'>
				{
					videos.map((video, i) =>
						<div key={`videos-${i + video.videoAnchor}`} className='videos--column' id={video.videoAnchor}>
							<h2 className='uppercase'>{video.videoTitle}</h2>
							<p>{video.videoDescription}</p>
							<div className='video'>
								<VideoPlayer
									id={video.videoId}
								/>
							</div>
							<div>
								<a className="kf-anchorlink gtm-transcript-cta transcript-cta" href={video.videoTransript} target="_blank" rel="noreferrer noopener">{video.lang === 'en' ? `View Video Transcript` : `Vea la Transcripción`}</a>
							</div>
						</div>
					)
				}
			</div>
		</section>

		<section className='relative pt-40 lg:pt-24'>
			<div className='bg-bottom'></div>
			<div className="container container--inner pb-20 xl:py-16">
				<BottomCta
					ctaUrl="/caregiver-resources"
					image={data}
				>
					Find <br className='block md:hidden' /> resources
				</BottomCta>
			</div>
		</section>
	</>)
}

export default VideoLibrary;


export const pageQuery = graphql`
  query {
	leftImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/getting-started/bottom-cta/getting-started-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title='Video Library - EMFLAZA® (deflazacort)'
			description='Watch videos featuring stories from families that may be going through similar DMD journeys, along with other useful information. See full Prescribing Information & Important Safety Information.'
		>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Making the Grade", "description": "Hear from a mother caring for her son with DMD on how she’s setting him up for success in school.", "thumbnailUrl": "https://i.vimeocdn.com/video/1049637288-317278817543b74c5dafc7f837d3dcc3cf85909aac6b7c787e2b6c6024aeed3e-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/video-library", "contentUrl": "https://player.vimeo.com/video/507119104", "duration": "PT2M10S" })}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Here & Now With the Losapios: A DMD Story", "description": "Mike has two sons with DMD. Here's how he makes sure they're making the most of the here & now.", "thumbnailUrl": "https://i.vimeocdn.com/video/1495639936-9394d172c865f6d6bcc0d7ea13c0525e6c9c92c3f79050852a191a56a3c0c75e-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/video-library", "contentUrl": "https://player.vimeo.com/video/740850133", "duration": "PT5M45S" })}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Here & Now With the Kasners: A DMD Story", "description": "Sarah and Dan work together to care for their two sons with DMD. Watch how they try to have fun and enjoy life as much as they can.", "thumbnailUrl": "https://i.vimeocdn.com/video/1495638772-25769eb9855a816959fbbd261c4e9b764ed6effdec4c920704f109965f99585b-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/about-emflaza", "contentUrl": "https://player.vimeo.com/video/740846411", "duration": "PT5M54S" })}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "He is Manny", "description": "Hear from a family as they discuss their experience with making treatment decisions.", "thumbnailUrl": "https://i.vimeocdn.com/video/1303849706-128543bac7cd91c55fa120583dfdbcb11fd1bb163e1bf67b9", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/video-library", "contentUrl": "https://player.vimeo.com/video/644529296", "duration": "PT8M20S" })}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Making A Switch: Christopher's Treatment Journey", "description": "Sima cares for her son every day with the help of her family. Hear how she works with the DMD community and PTC Cares™ along the way.", "thumbnailUrl": "https://i.vimeocdn.com/video/1543947810-df66fb5c4e6c62546048f9b1e8a4e1a2787bd1a086fd38a6adc7f08b4228277d-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/video-library", "contentUrl": "https://player.vimeo.com/video/768695028?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false&customControls=true", "duration": "TPT8M34S" })}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Haciendo un cambio: La historia de tratamiento de Christopher", "description": "Sima cuida de su hijo cada día con la ayuda de su familia. Escuche cómo trabaja con la comunidad de DMD y PTC Cares™ a lo largo del proceso.", "thumbnailUrl": "https://i.vimeocdn.com/video/1573557630-d754336bb65cc1cdc6b00d8554378609ea0f5d48bfcb0a949da1731edf580a05-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/video-library", "contentUrl": "https://player.vimeo.com/video/507119354", "duration": "PT11M27S" })}
			</script>
		</Seo>
	);
};
